<template>
  <!--  <van-swipe :autoplay="3000" lazy-render>
      <van-swipe-item v-for="image in images" :key="image">
        <van-image :src="image" fit="contain"/>
      </van-swipe-item>
    </van-swipe>
    -->
  <van-tabs v-model:active="active" @click-tab="toggle" :sticky="true">
    <van-tab v-for="tab in tabsList" :title="tab.label" :name="tab.value" :title-style="{'font-size': '1.2em'}">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :loosing-text="t('message.referenning')" :loading-text="t('message.referenning')"  >
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="$t('message.notMore')"
            @load="onLoad"
        >
          <div v-for="item in listMap[active]" :key="item">
            <van-cell v-if="item.recommend === 0">
              <template #title>
                <span style="font-size: 1.3em"  @click="linkOther(item.link)">{{ item.title }}</span>
                <van-tag v-if="item.top === 1" color="#e43030">{{$t('message.top')}}</van-tag>
              </template>
              <template #label>
                <img height="180" fit="cover" style="width: 100%;display: block;border-radius: 15px;margin: 10px auto auto;object-fit: cover"
                     @click="linkOther(item.link)"
                           v-if="item.coverImg" :src="item.coverImg" onerror="this.src='https://news-1259740107.cos.ap-singapore.myqcloud.com/icon/img_err.png'"/>
                <div style="float: left;margin-top: 10px;display: flex;"  @click="linkOther(item.link)">
                  <img :src="item.headImg" style="width: 25px;border-radius: 70px"  alt=""/>
                  <span style="display: flex;align-self: center;margin-left: 5px">{{ item.author }}</span>
                </div>
                <div style="float: right;line-height: 40px;" @click="toAuthor(11)">
                  {{ item.createTime }}
                </div>
              </template>
              <template #right-icon>
              </template>
            </van-cell>
            <van-cell v-else>
              <template #title>
                <div style="margin: 5px;" @click="ck(item)">
                  <van-tag v-if="item.top === 1" color="#e43030" style="line-height: 22px;float: left">{{$t('message.top')}}</van-tag>
                  <van-tag v-if="item.mediaType === 2" color="#2ddd00" style="line-height: 22px;float: left">{{$t('message.video')}}</van-tag>
                  <van-tag v-if="item.mediaType === 3" color="#4ab0dd" style="line-height: 22px;float: left">{{$t('message.live')}}</van-tag>
                  <span v-if="item.mediaType === 2 || item.mediaType === 3 || item.top === 1">&nbsp;</span>
                  <span style="font-size: 1.3em">{{ item.title }}</span>

                  <!--                  <van-tag v-if="item.mediaType === 1" color="#3b92ff">{{$t('message.content')}}</van-tag>
                                    <van-tag v-if="item.mediaType === 2" color="#ff21f8">{{$t('message.video')}}</van-tag>
                                    <van-tag v-if="item.mediaType === 3" color="#00bd5d">{{$t('message.live')}}</van-tag>-->
                </div>
              </template>
              <template #label>
                <div  v-if="item.imgType === 2">
                    <img v-if="item.mediaType !== 1" src="https://news-1259740107.cos.ap-singapore.myqcloud.com/icon/video.png" class="img_play"/>
                    <img height="200" fit="cover" style="width: 100%;display: block;margin: auto;object-fit: cover;border-radius: 15px;"
                               v-if="item.coverImgList" :src="item.coverImgList[0].url"  @click="ck(item)"/>
                  <div style="float: left;margin: 10px 0;display: flex;" @click="toAuthor(item.aid)">
                    <img :src="item.headImg" style="width: 25px;border-radius: 70px"  alt=""/>
                    <span style="display: flex;align-self: center;margin-left: 5px">
                      {{ item.author }}
                    </span>
                  </div>
                  <span style="display: flex;align-self: center;margin-left: 5px;margin-top: 13px;float: right">
                    {{ item.createTime }}
                  </span>
                </div>


                <div v-if="item.imgType === 3">
                  <div @click="ck(item)" v-if="item.coverImgList" style="width: 100%;display: block;margin: auto;object-fit: cover;">
                    <img style="object-fit: cover; width: 32%;height: 100px; border-radius: 5px;" :src="item.coverImgList[0].url"/>
                    <img style="object-fit: cover; width: 32%;height: 100px; border-radius: 5px; margin-left: 5px;" :src="item.coverImgList[1].url"/>
                    <img style="object-fit: cover; width: 32%;height: 100px; border-radius: 5px; margin-left: 5px;" :src="item.coverImgList[2].url"/>
                  </div>
                  <div style="float: left;margin: 10px 0;display: flex;" @click="toAuthor(item.aid)">
                    <img :src="item.headImg" style="width: 25px;border-radius: 70px"  alt=""/>
                    <span style="display: flex;align-self: center;margin-left: 5px">
                      {{ item.author }}
                    </span>
                  </div>
                  <span style="display: flex;align-self: center;margin-left: 5px;margin-top: 13px;float: right">
                    {{ item.createTime }}
                </span>
                </div>

                <span v-if="item.imgType === 1" style="float: left;display: flex" @click="toAuthor(item.aid)">
                  <img :src="item.headImg" style="width: 25px;border-radius: 70px"  alt=""/>
                  <span style="display: flex;align-self: center;margin-left: 5px">
                  {{ item.author }}
                  </span>
                </span>
                <span  v-if="item.imgType === 1" style="display: flex;align-self: center;float: right;margin-right: 10px">
                    {{ item.createTime }}
                </span>
              </template>
              <template #right-icon>
                <img v-if="item.imgType === 1 && item.mediaType !== 1"
                     src="https://news-1259740107.cos.ap-singapore.myqcloud.com/icon/video.png"
                     style="width: 25px;
                            position: absolute;
                            left: 85vw;
                            margin-left: -30px;
                            z-index: 22;
                            top: 45px;"
                />
                <van-image v-if="item.coverImgList && item.imgType === 1" width="30vw" fit="cover" style="max-height: 100px" :src="item.coverImgList[0].url" />
              </template>

            </van-cell>
            <van-divider />
          </div>
        </van-list>
      </van-pull-refresh>
    </van-tab>
  </van-tabs>
<!--
  <van-popup
      v-model:show="show"
      closeable
      :round="true"
      :duration="htd"
      position="bottom"
      :overlay="ol"
      @closed="closeVideo"
      :style="{ height: hg }">
    <div style="padding: 20px;">
      <h2>{{ ct.title }}</h2>
      <p style="color: #999999; font-size: 0.7em;margin: 10px 0">{{ ct.author }}</p>
      <p style="color: #999999; font-size: 0.6em;margin: 10px 0">{{ ct.viewTime }}</p>
      <van-image v-if="ct.type === 1 || ct.type === 3"
                 style="max-height: 200px;width: 50vw;display: block;margin: 10px auto;" fit="contain"
                 :src="ct.coverImgObj.url"/>
      <div class="contentImg" style="font-size: 1em;" v-if="ct.type === 1 || ct.type === 3" v-html="ct.content"/>
      <div v-show="ct.type === 2" style="width: 90vw;display: block;margin: auto;" id="abcdee"></div>
    </div>
    <footer class="footer">@SG Chat All rights reserved.</footer>
  </van-popup>
-->
  <footer class="footer">{{footInfo}}</footer>
</template>

<script>
import {ref} from 'vue';
import {Locale, Toast} from "vant";
import zhCN from 'vant/es/locale/lang/zh-CN';
import enUS from "vant/es/locale/lang/en-US";

import {useI18n} from 'vue-i18n'

import axios from "axios";
// import Player from "xgplayer";
import {useRouter} from "vue-router";

// const videoConfig = () => {
//   const {t} = useI18n();
//   return {
//     id: 'abcdee',
//     url: 'https://lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4',
//     errorTips: t('message.videoError'),
//     fitVideoSize: 'auto',
//     fluid: true,
//     videoInit: true
//   };
// };
const cot = () => {
  return {
    title: '',
    content: '',
    type: 2,
    author: '',
    url: '',
    coverImgObj: {
      url: ''
    },
  };
};

// https://xxx.com/home?lang=en-US english
// https://xxx.com/home?lang=zh-CN chinese
export default {
  setup() {
    const {t} = useI18n();
    const i18n = useI18n();
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const active = ref(0);
    const page = ref({});
    const total = ref([0]);
    const listMap = ref([[]]);
    const tabsList = ref([]);
    const baseTabsList = ref([]);
    const show = ref(true);
    const lang = ref('zh-CN');
    const langNum = ref(1);
    const ct = ref(cot());
    const htd = ref(0.0);
    const hg = ref("0");
    const player = ref()
    const ol = ref(false)
    const rft = ref([]);
    // const vpc = ref(videoConfig());
    const router = useRouter();
    const footInfo = ref('@SG Chat All rights reserved.');
    const baseURL = 'https://m.hk.saheadlines.com/';
    const par = router.currentRoute.value.query
    if (par.lang) {
      lang.value = par.lang;
    }
    const globalNation = '-1828618239';
    const onClickLeft = () => history.back();
    const toAuthor = (id) => {
      router.push({
        name: "person",
        params: {
          id: id,
        },
        query: {
          lang: lang.value
        }
      });
    }
    axios.get('rss/footInfo?nationId='+globalNation).then((e) => {
      footInfo.value = e.data.data;
    });
    const closeVideo = () => {
      if (htd.value === 0.0) {
        htd.value = 0.3;
      }
      if (hg.value === '0') {
        hg.value = '90%';
      }
      if (!ol.value) {
        ol.value = true;
      }
      if (player.value !== undefined) {
        player.value.pause();
      }
      ct.value.content = '';
    }
    const linkOther = (link) => {
      location.href = link;
    }
    const ck = (item) => {
      location.href = baseURL + 'news/content/'+item.id + '/'
      // router.push({
      //   name: "content",
      //   params: {
      //     id: item.id,
      //   }
      // });
      // axios.get('rss/content', {
      //   params: {
      //     id: item.id
      //   }
      // }).then((e) => {
      //   show.value = true;
      //   ct.value = e.data.data;
      //   if (ct.value.type === 2) {
      //     vpc.value.url = ct.value.content
      //     // vpc.value.poster = ct.value.coverImgObj.url
      //     if (player.value === undefined) {
      //       player.value = new Player(vpc.value);
      //     } else {
      //       player.value.src = ct.value.content;
      //     }
      //   }
      // });
    }
    const images = [
      // 'https://cdn.jsdelivr.net/npm/@vant/assets/apple-1.jpeg',
      // 'https://cdn.jsdelivr.net/npm/@vant/assets/apple-2.jpeg',
    ];
    const changeLang = () => {
      tabsList.value = [];
      if (lang.value === "zh-CN") {
        langNum.value = 2;
        Locale.use("zh-CN", zhCN);
        i18n.locale.value = 'zh'

      } else {
        langNum.value = 1;
        Locale.use('en-US', enUS);
        i18n.locale.value = 'en'
      }
      axios.get('rss/menuType?language='+langNum.value+'&nationId='+globalNation,).then(e => {
        show.value = false;
        baseTabsList.value = e.data.data;
        if (baseTabsList.value.length > 0) {
          active.value = baseTabsList.value[0].id
        }
        baseTabsList.value.forEach(e => {
          tabsList.value.push({
            value: e.id,
            label: e.cn
          });
        })

        Toast.clear();
      }).catch(() => {
        Toast.fail(t('message.error'));
      })
    }
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
    });

    // this.$i18n.locale = "zh"
    const getNews = async (page) => {
      await axios.get('rss/news', {
        params: {
          pageNum: page,
          pageSize: 10,
          type: active.value,
          language: langNum.value,
          ref: rft.value[active.value],
          nationId: globalNation,
        }
      }).then(e => {
        if(!listMap.value[active.value]) {
          listMap.value[active.value] = [];
        }
        if (page === 1) {
          axios.get('rss/topNews', {
            params: {
              type: active.value,
              language: langNum.value,
            }
          }).then((dae) => {
            listMap.value[active.value].push(...dae.data.data);
            listMap.value[active.value].push(...e.data.data);
            total.value[active.value] = e.data.total;
          })
        } else {
          listMap.value[active.value].push(...e.data.data);
          total.value[active.value] = e.data.total;
        }

      })
    }

    const onLoad = async () => {
      if (!page.value[active.value]) {
        page.value[active.value] = 1;
      }
      if (!rft.value[active.value]) {
        rft.value[active.value] = 1;
      }
      if (refreshing.value) {
        listMap.value[active.value] = [];
        page.value[active.value] = 1;
        if (rft.value[active.value] >= 6) {
          rft.value[active.value] = 1;
        } else {
          rft.value[active.value] += 1;
        }
        refreshing.value = false;
      }
      await getNews(page.value[active.value]);
      loading.value = false;
      page.value[active.value]++;
      if (listMap.value[active.value].length >= total.value[active.value]) {
        await getNews(page.value[active.value]);
        finished.value = true;
      }

    };

    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    const toggle = () => {
      if (!listMap.value[active.value]) {
        listMap.value[active.value] = [];
      }
      if (!page.value[active.value]) {
        page.value[active.value] = 1;
      }
      if (!total.value[active.value]) {
        total.value[active.value] = 0;
      }
      onRefresh();
    }
    changeLang();
    return {
      t,
      onLoad,
      loading,
      finished,
      onRefresh,
      refreshing,
      active,
      total,
      listMap,
      toggle,
      tabsList,
      images,
      changeLang,
      lang,
      ck,
      linkOther,
      ct,
      show,
      onClickLeft,
      closeVideo,
      toAuthor,
      htd,
      hg,
      footInfo,
      ol,
    };
  },
};
</script>

<style>
.img_play {
  width: 65px;
  position: absolute;
  left: 50vw;
  margin-left: -30px;
  z-index: 22;
  top: 110px;
}

.contentImg img {
  width: 100%;
  height: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 0.8em;
  color: #999999;
  text-align: center;
}
</style>
